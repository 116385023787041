(function () {
    'use strict';

    angular
        .module('App')
        .factory('ContentWrapperService', ['$http', '$stateParams', 'PopupWrapperService',
            'Page', ContentWrapperService]);

    function ContentWrapperService($http, $stateParams, PopupWrapperService, Page) {
        let ContentIdsToExclude = [], searchState = {};

        return {
            contentCommentsPopupId: 'contentCommentsPopupId',
            generateMediaFromSource: generateMediaFromSource,
            getContents: getContents,
            showCommentsModal: showCommentsModal,
            addContentToExclude: addContentToExclude,
            removeContentIdFromExclude: removeContentIdFromExclude,
            getContentIdsToExclude: getContentIdsToExclude,
            resetContentIdsToExclude: resetContentIdsToExclude,
            setCategoryFollowing: setCategoryFollowing,
            getCategories: getCategories,
            getSearchContentResults: getSearchContentResults,
            setSearchState: setSearchState,
            getSearchState: getSearchState,
            cleanSearchState: cleanSearchState,
            selectCurrentLocale: selectCurrentLocale,
        };

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function generateMediaFromSource(src) {
            const settings = Page.getSettings();
            const downloadUrl = settings.MediaServerDomain + '/download?file=' + src;

            return {
                ImageFormats: {
                    W100: src,
                    W250: src,
                    W600: src,
                    W1500: src
                },
                MediaTypeId: 1,
                OriginalUrl: src,
                DownloadUrl: downloadUrl,
            }
        }

        function showCommentsModal(content, settings, commentId, moduleToken) {
            return PopupWrapperService.createDynamic(
                `<content-comments-popup 
                                    post="content"
                                    settings="settings"
                                    comment-id="commentId"
                                    module-token="moduleToken"
                                ></content-comments-popup>`,
                {
                    content: content,
                    settings: settings,
                    commentId: commentId,
                    moduleToken: moduleToken
                }
            )
        }

        function getContents({
                                 token, categoryToken, offset = 0, limit = 20,
                    sortType, contentIdsToExclude, startTime, endTime, includeFuturePosts, archivePostsOnly
                             }) {
            return $http.post('/ContentModule/GetContents/' + token, {
                offset: offset,
                limit: limit,
                categoryToken: categoryToken,
                sortType: sortType,
                contentIdsToExclude: contentIdsToExclude,
                startTime: startTime ? moment(startTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                endTime: endTime ? moment(endTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                includeFuturePosts,
                archivePostsOnly
            }).then(resp => resp.data);
        }

        function addContentToExclude(contentId) {
            if (!_.find(ContentIdsToExclude, contentId)) {
                ContentIdsToExclude.push(contentId);
            }
        }

        function removeContentIdFromExclude(contentId) {
            ContentIdsToExclude = ContentIdsToExclude.filter(item => item !== contentId)
        }

        function getContentIdsToExclude() {
            return ContentIdsToExclude;
        }

        function resetContentIdsToExclude() {
            ContentIdsToExclude = [];
        }

        function setCategoryFollowing(categoryToken, unfollow) {
            return $http.post('/ContentModule/UpdateCategoryFollowing', {
                token: $stateParams.token,
                contentCategoryToken: categoryToken,
                shouldBeUnfollowed: unfollow
            }).then(resp => resp.data);
        }

        function getCategories(accountModuleToken) {
            return $http.get('/ContentModule/GetCategories/' + accountModuleToken).then(resp => resp.data)
        }

        function getSearchContentResults(searchData) {
            return $http.post('/SearchV2/SearchContentModule', {
                AccountModuleId: searchData.accountModuleId,
                SearchText: searchData.searchText,
                SortType: searchData.sortType,
                CategoryId: searchData.categoryId,
                AfterEntityType: searchData.afterEntityType,
                AfterEntityId: searchData.afterEntityId,
                Limit: searchData.limit,
                EndDate: searchData.endDate,
                StartDate: searchData.startDate,
                SelectedDateFilterId: searchData.selectedDateFilterId,
                IncludeFuturePosts: searchData.includeFuturePosts,
                ArchivePostsOnly: searchData.archivePostsOnly
            }).then(resp => resp.data);
        }

        function setSearchState(state) {
            searchState = state;
        }

        function getSearchState() {
            return searchState
        }

        function cleanSearchState() {
            searchState = {};
        }
    }
})();