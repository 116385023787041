(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('Menu', ['$http', '$translate', '$rootScope', '$window', 'NotificationFactory', 'events',
        function ($http, $translate, $rootScope, $window, NotificationFactory, events) {

            let appVersion, stylesVersion, sidebarMenu = [], homeMenu = [], landingPages = [],
                frontPage, chatNavigationBadge;

            return {
                hasData: hasData,
                sidebarMenu: getSidebarMenu,
                homeMenu: getHomeMenu,
                landingPages: getLandingPages,
                setMenus: setMenus,
                loadMenus: loadMenus,
                clearCache: clearCache,
                updateActivity: updateActivity,
                removeActivity: removeActivity,
                addActivity: addActivity,
                setCurrentMenu: setCurrentMenu,
                updateLandingBadge: updateLandingBadge,
                removeLandingBadge: removeLandingBadge,
                addLandingBadge: addLandingBadge,
                getFrontPage: getFrontPage,
                getChatNavigationBadge: getChatNavigationBadge

            };

            function hasData() {
                return (sidebarMenu && sidebarMenu.length || homeMenu && homeMenu.length);
            }

            function getSidebarMenu() {
                return sidebarMenu;
            }

            function getHomeMenu() {
                return homeMenu;
            }

            function getLandingPages() {
                return landingPages;
            }

            function setMenus(data, isMerge) {
                if (data) {
                    if (data.SideBar && data.SideBar.length) {
                        if (isMerge === true) {
                            mergeMenu(sidebarMenu, data.SideBar);
                        } else {
                            sidebarMenu = [];
                            sidebarMenu = translateMenu(data.SideBar);
                        }
                    }
                    if (data.Home && data.Home.length) {
                        if (isMerge === true) {
                            mergeMenu(homeMenu, data.Home);
                        } else {
                            homeMenu = [];
                            homeMenu = translateMenu(data.Home);
                        }
                    }
                    if (data.LandingPages) {
                        mergeLandingPages(data.LandingPages);
                    }
                    if (data.FrontPageToken) {
                        mergeFrontPage(_.find(data.LandingPages, {'LandingPageToken': data.FrontPageToken}));
                    }
                    if (data.ChatNavigationBadge) {
                        chatNavigationBadge = data.ChatNavigationBadge;
                        chatNavigationBadge.ChatModuleToken = data.ChatModuleToken;
                    }

                    if (!appVersion && !stylesVersion) {
                        appVersion = data.AppVersion;
                        stylesVersion = data.StylesVersion;
                    } else if (data.AppVersion !== appVersion || data.StylesVersion !== stylesVersion) {
                        $window.location.reload(true);
                    }
                }
            }

            function updateLandingBadge(amount, accountModuleId, moduleId, moduleItemId, badgeCount) {
                updateLandingBadgeCount(amount, 'equal', accountModuleId, moduleId, moduleItemId, _.isUndefined(badgeCount));
            }

            function removeLandingBadge(amount, accountModuleId, moduleId, moduleItemId) {
                updateLandingBadgeCount(amount, 'remove', accountModuleId, moduleId, moduleItemId);
            }

            function addLandingBadge(amount, accountModuleId, moduleId, moduleItemId) {
                updateLandingBadgeCount(amount, 'add', accountModuleId, moduleId, moduleItemId, true);
            }

            function updateLandingBadgeCount(amount, amountType, accountModuleId, moduleId, moduleItemId, updateBadge) {
                if (!landingPages) {
                    return false;
                }

                _.each(landingPages, function (page) {
                    if (page.Tiles && page.Tiles.length) {
                        const tiles = page.Tiles.filter(tile => {
                            if (moduleItemId) {
                                return (tile.ModuleItemId === moduleItemId || tile.ModuleItemId === null) && tile.AccountModuleId === accountModuleId
                            } else {
                                return tile.AccountModuleId === accountModuleId
                            }
                        })
                        _.each(tiles, function (tile) {
                            switch (amountType) {
                                case 'equal':
                                    (updateBadge && tile.Badge) && NotificationFactory.adjustModuleBadgeCount(-tile.Badge);
                                    tile.Badge = parseInt(amount);
                                    break;
                                case 'remove':
                                    (updateBadge && amount) && NotificationFactory.adjustModuleBadgeCount(-amount);
                                    var badgeCount = parseInt(tile.Badge) - parseInt(amount);
                                    tile.Badge = badgeCount >= 0 ? badgeCount : 0;
                                    break;
                                case 'add':
                                    (updateBadge && amount) && NotificationFactory.adjustModuleBadgeCount(amount);
                                    var badgeCount = parseInt(tile.Badge) + parseInt(amount);
                                    tile.Badge = badgeCount >= 0 ? badgeCount : 0;
                                    break;
                            }
                        });
                    }
                });
                _.each(landingPages, function (page) {
                    const tiles = _.filter(page.Tiles, function (tile) {
                        return tile.ChildToken;
                    });

                    _.each(tiles, function (tile) {
                        var landing = _.find(landingPages, {LandingPageToken: tile.ChildToken}),
                            count = 0;
                        _.each(landing.Tiles, function (tile) {
                            count += tile.Badge;
                        });

                        if (!tile.HideBadge) {
                            tile.Badge = count;
                        }
                        const menuItems = 
                            sidebarMenu.filter(item => item.InnerLandingPageToken === landing.LandingPageToken);
                        if (menuItems && menuItems.length) {
                            menuItems.forEach(item => {
                                item.Badge = count;
                            })
                        } 
                    });
                });
            }

            function loadMenus(isMerge, isRequired) {
                var url = '/Navigation/GetNavigation';
                if (isRequired === true) {
                    url += '?required=true';
                }
                return $http.get(url)
                    .success(function (data) {
                        NotificationFactory.setNativeBadgeCount(data.ModuleBadgeCount, data.NotificationCenterBadgeCount, data.UnreadChatsCount);
                        setMenus(data, isMerge);

                        return data;
                    });
            }

            function clearCache() {
                sidebarMenu = null;
                homeMenu = null;
            }

            function updateActivity(count, stateName, token, extra) {
                var badgeCount,
                    sideMenuItem = _.find(sidebarMenu, {'Token': token}),
                    homeMenuItem = _.find(homeMenu, {'Token': token});
                // SidebarMenu
                if (sidebarMenu !== null && sideMenuItem && sideMenuItem.AllowNotifications) {
                    badgeCount = sideMenuItem.Badge;
                    sidebarMenu = updateActivityFunc(sidebarMenu, count, stateName, token, extra);
                }

                // HomeMenu
                if (homeMenu !== null && homeMenuItem && homeMenuItem.AllowNotifications) {
                    badgeCount = homeMenuItem.Badge;
                    homeMenu = updateActivityFunc(homeMenu, count, stateName, token, extra);
                }

                if (badgeCount) {
                    NotificationFactory.adjustModuleBadgeCount(-badgeCount);
                }
                $rootScope.$broadcast(events.MENU_UPDATED);
                return badgeCount;
            }

            function removeActivity(count, stateName, token, extra) {
                manageActivity('remove', count, stateName, token, extra);
            }

            function addActivity(count, stateName, token, extra) {
                manageActivity('add', count, stateName, token, extra);
            }

            function manageActivity(action, count, stateName, token, extra) {
                var sideMenuItem = _.find(sidebarMenu, {'Token': token}),
                    homeMenuItem = _.find(homeMenu, {'Token': token});
            
                // SidebarMenu
                if (sidebarMenu !== null && sideMenuItem && sideMenuItem.AllowNotifications) {
                    sidebarMenu = manageActivityFunc(sidebarMenu, action, count, stateName, token, extra);
                }
                // HomeMenu
                if (homeMenu !== null && homeMenuItem && homeMenuItem.AllowNotifications) {
                    homeMenu = manageActivityFunc(homeMenu, action, count, stateName, token, extra);
                }
                
                var adjustCount = (action === 'add') ? count : -count;
                NotificationFactory.adjustModuleBadgeCount(adjustCount);
                $rootScope.$broadcast(events.MENU_UPDATED);
            }
            
            function setCurrentMenu(stateName, token, extra) {
                if (sidebarMenu !== null) {
                    sidebarMenu = setCurrentMenuFunc(sidebarMenu, stateName, token, extra);
                }
            }

            function translateMenu(menu) {
                for (var i = 0; i < menu.length; i++) {
                    if (menu[i].TranslateTitle) {
                        translateMenuItem(menu[i]);
                    }

                    for (var ii = 0; ii < menu[i].Children.length; ii++) {
                        if (menu[i].Children[ii].TranslateTitle) {
                            translateMenuItem(menu[i].Children[ii]);
                        }
                    }
                }
                return menu;
            }

            function translateMenuItem(item) {
                $translate(item.Title).then(function (translation) {
                    item.Title = translation;
                });
            }

            function mergeMenu(oldMenu, newMenu) {
                // Go through all current menu items
                _.each(oldMenu, function (value, key) {
                    // Find the same item from the new menu
                    var newMenuItem = newMenu[key];
                    if (newMenuItem) {
                        // Update the badge value
                        value.Badge = newMenuItem.Badge;
                        // Do the same for child items if present
                        if (value.Children.length) {
                            mergeMenu(value.Children, newMenuItem.Children);
                        }
                    }
                });
            }

            function manageActivityFunc(menu, action, count, stateName, token, extra) {
                for (var i = 0; i < menu.length; i++) {
                    let isContent = stateName === "content" || stateName === "contentOverview";
                    var res = checkMenuItem(menu[i], stateName, token, extra);
                    if (menu[i].Children.length > 0) {       

                        // Keep trying
                        for (var ii = 0; ii < menu[i].Children.length; ii++) {
                            var child = checkMenuItem(menu[i].Children[ii], stateName, token, extra);

                            if (child !== null && !_.isNull(extra) && !isContent) {
                                menu[i].Children[ii].Badge = updateBadge(menu[i].Children[ii].Badge, action, count);
                                menu[i].Badge = updateBadge(menu[i].Badge, action, count);
                            } else if (child !== null && isContent) {
                                if (ii !== 0) { // if category view disabled in side menu 'All categories' doesn't have badge
                                    menu[i].Children[ii].Badge = updateBadge(menu[i].Children[ii].Badge, action, count);   
                                }
                                menu[i].Badge = updateBadge(menu[i].Badge, action, count);

                                if (menu[i].Badge === 0) {
                                    menu[i] = resetChildrenBadges(menu[i]);
                                }
                            }                  
                        }
                    } else if (res !== null) {
                        // Adjust the Badge
                        menu[i].Badge = updateBadge(menu[i].Badge, action, count);
                    }
                }
            
                return menu;
            }

            function resetChildrenBadges(menuItem) {
                return {
                    ...menuItem,
                    Children: menuItem.Children.map(child => ({
                        ...child, 
                        Badge: 0
                    }))
                };
            }
            
            function updateBadge(currentBadge, action, count) {
                if (action === 'add') {
                    return currentBadge + count;
                } else if (action === 'remove') {
                    return currentBadge - count;
                }
                return currentBadge;
            }            

            function updateActivityFunc(menu, count, stateName, token, extra) {
                for (var i = 0; i < menu.length; i++) {

                    var res = checkMenuItem(menu[i], stateName, token, extra);
                    if (menu[i].Children.length > 0) {

                        // Keep trying
                        var childCount = 0;
                        for (var ii = 0; ii < menu[i].Children.length; ii++) {
                            var child = checkMenuItem(menu[i].Children[ii], stateName, token, extra);

                            if (child !== null) {
                                if (ii === 0) {
                                    menu[i] = resetAllBadges(menu[i]);
                                } else {
                                    menu[i].Children[ii].Badge = count;
                                    childCount += count;
                                }
                            } else {
                                childCount += menu[i].Children[ii].Badge;
                            }
                        }

                        menu[i].Badge = childCount;

                    } else if (res !== null) {
                        // Count down
                        menu[i].Badge = count;
                    }
                }

                return menu;
            }

            function resetAllBadges(menuItem) {
                return {
                    ...menuItem,
                    Badge: 0,
                    Children: menuItem.Children.map(child => ({
                        ...child, 
                        Badge: 0
                    }))
                };
            }

            function setCurrentMenuFunc(menu, stateName, token, extra) {
                for (var i = 0; i < menu.length; i++) {
                    // Reset current state
                    menu[i].current = false;
                    menu[i].open = false;

                    var res = checkMenuItem(menu[i], stateName, token, extra);
                    var childFound = false;
                    if (menu[i].Children.length > 0) {

                        // Keep trying
                        for (var ii = 0; ii < menu[i].Children.length; ii++) {
                            var child = checkMenuItem(menu[i].Children[ii], stateName, token, extra);

                            // Reset current state
                            menu[i].Children[ii].current = false;

                            if (child !== null) {
                                menu[i].Children[ii].current = true;
                                childFound = true;
                            }
                        }

                        if (childFound) {
                            menu[i].open = true;
                        }

                    } else if (res !== null && !childFound) {
                        // Set as current
                        menu[i].current = true;
                    }
                }

                return menu;
            }

            function checkMenuItem(menuItem, stateName, token, extra) {
                if (stateName === "content" && menuItem.State === "contentOverview" && menuItem.Children.length === 0) {
                    stateName = "contentOverview";
                }

                if (menuItem.State === stateName) {
                    if (menuItem.Token !== null) {
                        if (menuItem.Token === token) {
                            if (!_.isEmpty(menuItem.Extra) && menuItem.Extra === extra) {
                                return menuItem;
                            }
                            if (_.isEmpty(extra) && _.isEmpty(menuItem.Extra)) {
                                return menuItem;
                            }
                        }
                    }
                    if (token === null || token === undefined || token.length === 0) {
                        return menuItem;
                    }
                }
                return null;
            }

            function getFrontPage() {
                return frontPage;
            }

            function getChatNavigationBadge() {
                return chatNavigationBadge
            }

            function mergeLandingPages(newLandingPages){
                if(!landingPages.length){
                    landingPages = newLandingPages;
                    return;
                }

                var landingPagesToRemove = [];
                // merge landing pages
                _.each(landingPages, lp => {
                    var newLandingPage = _.find(newLandingPages, o => o.LandingPageToken === lp.LandingPageToken);
                    
                    if(!newLandingPage){
                        landingPagesToRemove.push(lp.LandingPageToken);
                        return;
                    }

                    mergeLandingPage(lp, newLandingPage);
                });

                // remove landing pages no longer valid
                _.each(landingPagesToRemove, token => {
                    _.remove(landingPages, lp => lp.LandingPageToken === token);
                });

                // add new landing pages
                _.each(newLandingPages, lp => {
                    var existingLandingPage = _.find(landingPages, o => o.LandingPageToken === lp.LandingPageToken);
                    if(!existingLandingPage){
                        landingPages.push(lp);
                    }
                });
            }

            function mergeFrontPage(newFrontPage){
                if(!frontPage || frontPage.LandingPageToken !== newFrontPage.LandingPageToken){
                    frontPage = newFrontPage;
                    return;
                }
            }

            function mergeLandingPage(landingPage, newLandingPage){
                // Merge existing properties (IMPORTANT: Don't use _.merge as we cannot replace the tile logic that way)
                _.each(landingPage, (value, key) => {
                    if(key === "Tiles"){
                        mergeTiles(landingPage[key], newLandingPage[key]);
                    }else if((key in newLandingPage) && typeof newLandingPage[key] !== typeof undefined){
                        landingPage[key] = newLandingPage[key];
                    }
                });

                // Add new properties
                _.each(newLandingPage, (value, key) => {
                    if(!(key in landingPage) && typeof value !== typeof undefined){
                        landingPage[key] = value;
                    }
                });
            }

            function mergeTiles(tiles, newTiles){
                var tilesToRemove = [];

                // merge tiles
                _.each(tiles, t => {
                    var newTile = _.find(newTiles, o => o.LandingPageTileId === t.LandingPageTileId);

                    if(!newTile){
                        tilesToRemove.push(t.LandingPageTileId);
                        return;
                    }

                    _.merge(t, newTile);
                });


                //console.log(tilesToRemove);
                // remove tiles no longer valid
                _.each(tilesToRemove, id => {
                    _.remove(tiles, t => t.LandingPageTileId === id);
                });

                // add new tiles to array
                _.each(newTiles, t => {
                    var existingTile = _.find(tiles, o => o.LandingPageTileId === t.LandingPageTileId);
                    if(!existingTile){
                        tiles.push(t);
                    }
                });
            }
        }]);
})();