(() => {
    'use strict';

    angular
        .module('App')
        .component('createUpdateTaskPopup', {
            template: require('./CreateUpdateTaskComponent.html'),
            controller: ['$element', '$rootScope', '$scope', '$timeout', 'ToastFactory', 'Page', 'Profile',
                'CreateUpdateTaskService', 'LanguageSelectorService', 'TranslationService',
                CreateTaskController],
            controllerAs: 'ctrl',
            bindings: {
                onPopupRegistered: '<'
            }
        });

    function CreateTaskController($element, $rootScope, $scope, $timeout, ToastFactory, Page, Profile,
                                  CreateUpdateTaskService, LanguageSelectorService,
                                  TranslationService) {
        const ctrl = this;
        let popup, translationWatcher, validationWatcher, taskChangeWatcher, loadingWatcher;

        ctrl.uploadUrl = Page.getSettings().MediaServerDomain + '/Upload'
        ctrl.uploadParams = {
            AccountToken: Profile.getProfile().AccountToken,
            UserToken: Profile.getProfile().UserToken
        }
        ctrl.translatedText = [];
        ctrl.fieldsVisibility = {};

        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.save = save;

        ctrl.changeDueSoon = changeDueSoon;
        ctrl.changeReminder = changeReminder;
        ctrl.getTranslation = getTranslation;
        ctrl.isTranslateDisabled = isTranslateDisabled;
        ctrl.languageLoaded = languageLoaded;
        ctrl.permissionLoaded = permissionLoaded;
        
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;

        function onOpen(detailsData, popupCtrl) {
            popup = popupCtrl;

            ctrl.task = {
                StartTime: {},
                EndTime: {},
                LocaleList: []
            };

            ctrl.isLoading = true;
            ctrl.issueToken = null;
            ctrl.showValidation = false;

            if (detailsData) {
                ctrl.issueToken = detailsData.token;
                ctrl.isParentIssue = detailsData.isParentIssue;
            }
            if (ctrl.issueToken) {
                ctrl.isEdit = true;
            } else {
                ctrl.isEdit = false;
                setInitialTime();
            }

            const getDataPromise = ctrl.isEdit ?
                CreateUpdateTaskService.getUpdatePage(ctrl.issueToken) :
                CreateUpdateTaskService.getCreatePage();

            getDataPromise.then(data => {
                initData(data, ctrl.isEdit);
            }).finally(() => {
                ctrl.isLoading = false;

                !ctrl.cultures?.length && languageLoaded();
                ctrl.task.IsRecurringChild && permissionLoaded();
                
                loadingWatcher = $scope.$watchGroup(['ctrl.isPermissionLoaded', 'ctrl.isLanguageLoaded'], () => {
                    if (ctrl.isLanguageLoaded && ctrl.isPermissionLoaded) {
                        iniTaskChangeWatcher();
                    }
                });
            })
        }
        
        function languageLoaded() {
            ctrl.isLanguageLoaded = true;
        }

        function permissionLoaded() {
            ctrl.isPermissionLoaded = true;
        }

        function initData(data, isEdit) {
            ctrl.cultures = LanguageSelectorService.updateLanguagesInfo(data.Cultures);
            ctrl.permissionSettings = {
                hideUserGroups: false,
                requirePermissionId: data.Settings.PermissionSettingType,
                hideDepartments: !data.Settings.FilterByDepartment,
                departmentFilterType: data.Settings.FilterByDepartmentType,
                requireDepartment: data.Settings.RequireDepartmentFilter,
                matchAllGroups: data.Settings.MatchAllGroups,
                userGroupsLimited: true // always true on the TM module
            };
            ctrl.categories = data.AllCategories;
            ctrl.ServiceForms = data.ServiceForms;
            ctrl.ReminderDueSoonNotificationEnabled = data.ReminderDueSoonNotificationEnabled;
            ctrl.ReminderOverdueNotificationEnabled = data.ReminderOverdueNotificationEnabled;
            ctrl.departmentTypes = [{ Name: 'DEPARTMENT_TYPE.NONE', DepartmentTypeId: null}].concat(data.DepartmentTypes);
            ctrl.AllowAutomaticTranslation = data.Settings.AllowAutomaticTranslation;
            ctrl.fieldsVisibility = data.Settings.FieldsVisibility;
            ctrl.requireCategories = data.Settings.RequireCategories;
            ctrl.privateTasksEnabled = data.Settings.PrivateTasksEnabled;

            if (isEdit) {
                ctrl.task = data.task;
                ctrl.task.AreMultiplePermissionsSet = data.Settings.MultiplePermissionSetsEnabled;
                ctrl.HasSubtasks = data.task.HasSubtasks;
                ctrl.task.showReminderInterval = !!ctrl.task.ReminderInterval;
                ctrl.task.showReminderDueSoonInterval = !!ctrl.task.ReminderDueSoonInterval;
                ctrl.translatedText = ctrl.task.LocaleList;
                ctrl.task.maximumMonthTaskOccurrence = data.Settings.MaximumMonthTaskOccurrence;
            } else {
                ctrl.task.Token = data.AccountModuleToken;
                ctrl.task.CompleteByDepartmentTypeId = data.CompleteByDepartmentTypeId &&
                    data.CompleteByDepartmentTypeId.toString() || null;
                ctrl.task.LockByStartDate = data.Settings.LockByStartDateDefault;
                ctrl.task.LockByEndDate = data.Settings.LockByEndDateDefault;
                ctrl.task.MaximumMonthTaskOccurrence = data.Settings.MaximumMonthTaskOccurrence;
                ctrl.task.AreMultiplePermissionsSet = data.Settings.MultiplePermissionSetsEnabled;
                ctrl.task.DisableComments = data.Settings.DisableCommentsByDefault
            }

            $timeout(() => {
                ctrl.AllowAutomaticTranslation && initTranslationWatcher()
                initValidation();
            })
        }

        function setInitialTime() {
            ctrl.task.StartTime.Recurrent = getTime(1);
            ctrl.task.EndTime.Recurrent = getTime(2);
            ctrl.task.StartTime.DateTime = getTime(1);
            ctrl.task.EndTime.DateTime = getTime(2);

            function getTime(add) {
                return new Date(moment().add(add, 'h').minutes(0).seconds(0).milliseconds(0));
            }
        }

        function close() {
            translationWatcher && translationWatcher();
            validationWatcher && validationWatcher();
            taskChangeWatcher && taskChangeWatcher();
            loadingWatcher && loadingWatcher();
            popup.remove();
            $element.remove();
        }

        function save(task) {
            if (!ctrl.taskForm.$valid || !ctrl.isLanguagesValid) {
                ctrl.showValidation = true;

                $timeout(function () {
                    $element.find('multiple-permission-selector-wrapper.ng-invalid').length &&
                    $element.find('.scroll-container').scrollTop($element.find('.ng-invalid').offset().top);
                });

                return false;
            }

            if (ctrl.task.RecurrenceRule && !isRepeatUntilValid()){
                ToastFactory.errorTranslated('TASK_MANAGEMENT.TASK.INVALID_TIME');
                return false;
            }

            if (ctrl.issueToken) {
                edit(task);
            } else {
                create(task);
            }
        }

        function create(task) {
            ctrl.isSaving = true;
            CreateUpdateTaskService.createTask(task).then(function (task) {
                ctrl.isSaving = false;
                ToastFactory.successTranslated('TASK_MANAGEMENT.TASK.CREATED');
                $rootScope.$emit('TaskManagement:taskCreated', task, ctrl.isParentIssue);
                close();
            }).catch(function (resp) {
                ctrl.isSaving = false;
                ToastFactory.errorTranslated(_.get(resp, 'data.Message'));
            });
        }

        function edit(task) {
            ctrl.isSaving = true;
            CreateUpdateTaskService.editTask(task).then(editedTask => {
                ctrl.isSaving = false;
                ToastFactory.successTranslated('TASK_MANAGEMENT.TASK.UPDATED');
                $rootScope.$emit('TaskManagement:taskEdited', editedTask, ctrl.isParentIssue);
                close();
                if (ctrl.isParentIssue) {
                    $timeout(() => {
                        Page.stateReload();
                    })
                }
            }).catch(resp => {
                ctrl.isSaving = false;
                ToastFactory.errorTranslated(_.get(resp, 'data.Message'));
            });
        }

        function changeDueSoon() {
            ctrl.task.ReminderDueSoonInterval = ctrl.task.showReminderDueSoonInterval ? 1 : null;
        }

        function changeReminder() {
            ctrl.task.ReminderInterval = ctrl.task.showReminderInterval ? 1 : null;
        }

        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.task.LocaleList[ctrl.selectedLanguageIndex]?.CultureId
                const languages = ctrl.task.LocaleList
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }

        function translate() {
            const {Token, LocaleList} = ctrl.task;
            ctrl.isTranslating = true;

            TranslationService.translateTask(Token, LocaleList, ctrl.selectedLanguageIndex).then(translations => {
                translations.translations && translations.translations.forEach(translation => {
                    const locale = ctrl.task.LocaleList.find(culture => culture.CultureId === translation.CultureId);

                    locale.Title = translation.Title;
                    locale.InitialTitle = translation.Title;
                    locale.Summary = translation.Summary;
                    locale.InitialSummary = translation.Summary;
                    locale.AutoTranslated = true;
                })
                ctrl.translatedText = _.cloneDeep(ctrl.task.LocaleList);
                ctrl.task.LocaleList[ctrl.selectedLanguageIndex].AutoTranslated = false;
            }).finally(() => {
                ctrl.isTranslating = false;
                ctrl.translateDisabled = true;
            })
        }

        function isTranslateDisabled() {
            return ctrl.isTranslating || ctrl.translateDisabled
        }

        function isRepeatUntilValid() {
            if (!ctrl.task.RepeatUntil || !ctrl.task.StartTime.Recurrent) return true;
            
            const date = ctrl.task.RepeatUntil;

            if (ctrl.task.EndTime.Recurrent) {
                const endTimeDate = new Date(ctrl.task.EndTime.Recurrent);
                date.setHours(endTimeDate.getHours(), endTimeDate.getMinutes(), endTimeDate.getSeconds());
            }
          
            return new Date(ctrl.task.StartTime.Recurrent) < date;
        }
        
        function iniTaskChangeWatcher() {
            let initializing = true;

            taskChangeWatcher = $scope.$watch(() => ctrl.task,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function initTranslationWatcher() {
            translationWatcher = $scope.$watch(() => {
                    return ctrl.task.LocaleList[ctrl.selectedLanguageIndex];
                },
                (newVal) => {
                    const currentItem = ctrl.translatedText?.find((item) => item.CultureId === newVal?.CultureId);
                    ctrl.translateDisabled = !newVal ||
                        ((newVal?.Title ?? '').trim() === (currentItem?.Title ?? '').trim() &&
                            (newVal?.Summary ?? '').trim() === (currentItem?.Summary ?? '').trim())
                    ctrl.isTaskChanged = ctrl.isTaskChanged || !ctrl.translateDisabled;

                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.task.LocaleList[ctrl.selectedLanguageIndex].AutoTranslated = false;
                    }
                }, true
            )
        }

        function initValidation() {
            validationWatcher = $scope.$watch('ctrl.task.LocaleList', () => {
                if (ctrl.task.LocaleList?.length) {
                    function isUserLanguageValid() {
                        return ctrl.task.LocaleList[0].Title
                    }

                    function isAdditionalLanguagesValid() {
                        return ctrl.task.LocaleList.slice(1).every(el => {
                            return (el.Title || (!el.Title && !el.Summary))
                        })
                    }

                    function isLanguagesValid() {
                        return ctrl.task.LocaleList.every(el => {
                            return (el.Title || (!el.Title && !el.Summary))
                        })
                    }

                    function someLanguageFilled() {
                        return ctrl.task.LocaleList.some(el => el.Title)
                    }

                    if (Page.getSettings().CulturesRework) {
                        ctrl.isLanguagesValid = isLanguagesValid() && someLanguageFilled();

                    } else {
                        ctrl.isLanguagesValid = isUserLanguageValid() && isAdditionalLanguagesValid();
                    }
                }
            }, true)
        }

        function isShowTranslationChangeWarning() {
            return ctrl.task.LocaleList
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.Title?.trim() !== item?.Title?.trim() ||
                        lang.Summary?.trim() !== item?.Summary?.trim();
                })
        }
    }
})();
