(() => {
    angular
        .module('App')
        .component('userManagementFilters', {
            template: require('./UserManagementFilters.html'),
            controllerAs: 'ctrl',
            bindings: {
                onFiltered: '<',
                allowedDepartments: '<',
                model: '<',
                activityManagerViewEnabled: '<',
                close: '<'
            },
            controller: ['$http', '$stateParams', 'UserManagementService', UserManagementFiltersController]
        })

    function UserManagementFiltersController($http, $stateParams, UserManagementService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.applyFilters = applyFilters;
        ctrl.resetFilters = resetFilters;
        ctrl.getActiveFiltersCount = getActiveFiltersCount;

        function init() {
            ctrl.model = ctrl.model || UserManagementService.getCleanFilterModel();
            ctrl.departmentSettings = {
                departments: ctrl.allowedDepartments
            };

            ctrl.sortingOptions = UserManagementService.getSortingOptions();
            ctrl.activityOptions = UserManagementService.getActivityOptions();
        }

        function applyFilters() {
            getFilteredData({
                SortProperty: ctrl.model.sorting.SortProperty && ctrl.model.sorting.SortProperty.value,
                ActivityStatus: !ctrl.model.filters.Recent && ctrl.model.filters.ActivityStatus.value,
                IsAsc: ctrl.model.sorting.SortProperty && ctrl.model.sorting.SortProperty.value == 1,//compare with string
                DepartmentIds: ctrl.model.filters.DepartmentIds,
                UserGroupId: ctrl.model.filters.UserGroupId,
                ShowOnlyRecent: ctrl.model.filters.Recent
            }).then(resp => {
                ctrl.close && ctrl.close();
                ctrl.onFiltered && ctrl.onFiltered(resp.data, ctrl.model, getActiveFiltersCount());
            })
        }

        function resetFilters() {
            ctrl.model = UserManagementService.getCleanFilterModel();
            ctrl.resetDepartment && ctrl.resetDepartment();
            ctrl.resetUsergroup && ctrl.resetUsergroup();

        }

        function getFilteredData(options) {
            return $http.post('/UserManagement/FilterUsers/' + $stateParams.token, options)
        }

        function getActiveFiltersCount() {
            return Object.keys(ctrl.model.filters).length && Object.keys(ctrl.model.filters)
                .map(key => allowIncreaseFilter(key))
                .reduce((prev, curr) => prev + curr)
        }

        function allowIncreaseFilter(filterName) {
            switch (filterName) {
                case 'ActivityStatus':
                    return (!ctrl.model.filters.Recent && ctrl.model.filters.ActivityStatus.value !== null) ? 1 : 0;
                case 'Recent':
                    return ctrl.model.filters.Recent ? 1 : 0
                default:
                    return (ctrl.model.filters[filterName] && ctrl.model.filters[filterName].toString().length) ? 1 : 0
            }
        }
    }
})();