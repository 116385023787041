(function () {
    'use strict';

    angular
        .module('App')

        .component('eventDetails', {
            template: require('./EventDetailsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$rootScope', '$scope', '$filter', '$element', '$timeout', 'CalendarRestService',
                'CalendarRenderService', 'CreateUpdateEventService', 'AddEventUpdateService', 'BasicHelper',
                'EventDetailsService', 'ActionSheetService', 'ConfirmPopupService', 'ToastFactory', 'CancelEventService',
                'Page', 'ServiceFormExternalService', 'TranslationService', 'ReportPopupService', EventDetailsController],
            bindings: {
                eventId: '<',
                isUpdate: '<',
                accountModuleToken: '<'
            }
        });

    function EventDetailsController($stateParams, $rootScope, $scope, $filter, $element, $timeout, CalendarRestService,
        CalendarRenderService, CreateUpdateEventService, AddEventUpdateService, BasicHelper,
        EventDetailsService, ActionSheetService, ConfirmPopupService, ToastFactory,
        CancelEventService, Page, ServiceFormExternalService, TranslationService, ReportPopupService) {
        const ctrl = this;
        let popup, removeLocationListener, removeSignupsListener, fillingCreatedListener;

        ctrl.popupId = 'EventDetailsPopup';
        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.firstDecline = null;
        ctrl.eventHasDraft = false;

        ctrl.attendanceType = {
            attendanceList: 1,
            signups: 0
        }

        ctrl.$onInit = init;
        ctrl.editEvent = editEvent;
        ctrl.signUpForEvent = signUpForEvent;
        ctrl.openSignups = openSignups;
        ctrl.toggleSignUp = toggleSignUp;
        ctrl.openOptions = openOptions;
        ctrl.openCreateEventUpdate = openCreateEventUpdate;
        ctrl.onShowEventUpdateOptions = onShowEventUpdateOptions;
        ctrl.cancelEvent = cancelEvent;
        ctrl.eventUpdatesRendered = eventUpdatesRendered;
        ctrl.openFilling = openFilling;
        ctrl.noButtonClass = noButtonClass;
        ctrl.toggleInterested = toggleInterested;
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.translationAllowed = translationAllowed;
        ctrl.attendingEventClickHandler = attendingEventClickHandler;
        ctrl.checkForAvailableSpots = checkForAvailableSpots;
        ctrl.registrationStatus = {
            Open: 1,
            NotOpenYet: 2,
            Closed: 3
        }
        ctrl.eventTypes = {
            Event: 1,
            ToDo: 2
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function init() {
            ctrl.isLoading = true;

            CalendarRenderService.getEventPreviewData(ctrl.eventId, ctrl.accountModuleToken).then(event => {
                ctrl.event = event;

                ctrl.showOriginal = true;

                if (translationAllowed()) {
                    ctrl.showOriginalLabel = ctrl.event.TranslatableEntity.PreferTranslatedVersion && isAllTranslated();
                    ctrl.showOriginal = !(ctrl.event.PreferEventTranslate && isSomeTranslated());
                }

                setCurrentCultures();

                ctrl.attendingStatuses = [
                    { id: 1, icon: 'save', label: 'CALENDAR.EVENT.ATTENDING', token: ctrl.event.AttendingFormToken },
                    { id: 2, icon: 'help', label: 'CALENDAR.EVENT.TENTATIVE', token: ctrl.event.TentativeFormToken },
                    {
                        id: 3,
                        icon: 'close',
                        label: 'CALENDAR.EVENT.NOT_ATTENDING',
                        token: ctrl.event.NotAttendingFormToken
                    },
                ]

                ctrl.startTime = moment(ctrl.event.StartTimeLocal).format('HH:mm');
                ctrl.startDate = moment(ctrl.event.StartTimeLocal).format('ddd, DD MMM');
                ctrl.endTime = moment(ctrl.event.EndTimeLocal).format('HH:mm');
                ctrl.endDate = moment(ctrl.event.EndTimeLocal).format('ddd, DD MMM');
                ctrl.event.SignupEnabled && setSpotTextAndClass();

                ctrl.isIos = BasicHelper.isIOS();
                $timeout(() => {
                    ctrl.isLoading = false;
                })

            }).catch(_ => {
                ToastFactory.errorTranslated('CALENDAR.EVENT.NO_ACCESS');
                close();
            });
            $timeout(() => {
                removeLocationListener = $scope.$on('$locationChangeSuccess', function (ev) {
                    if (!_.get(ev, 'currentScope.ctrl.isManual')) {
                        close(true);
                    }
                });
            })

            removeSignupsListener = $rootScope.$on('updateListOfSignupUsers', function (e, event) {
                updateEvent(event);
                ctrl.event.SignupEnabled && setSpotTextAndClass();
                $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
            });

            fillingCreatedListener = $rootScope.$on('ServiceFrom: FillingCreated', function (e, calendarEventId, filling) {
                if (ctrl.event.AttendingStatus) {
                    if (filling && !filling.IsDraft) {
                        if (ctrl.event.AttendingStatus === 1 || ctrl.event.AttendingStatus === 2) {
                            ToastFactory.successTranslated('CALENDAR.EVENT.SIGNUP_CONFIRM_MESSAGE');
                        } else {
                            ToastFactory.errorTranslated('CALENDAR.EVENT.DECLINE_CONFIRM_MESSAGE');
                        }
                    }
                    else {
                        ToastFactory.errorTranslated('CALENDAR.EVENT.SIGNUP_NOT_POSSIBLE');
                    }
                }

                ctrl.firstDecline = null;
            })
        }

        function translationAllowed() {
            return ctrl.event.TranslatableEntity.AllowAutoTranslation ||
                (!ctrl.event.TranslatableEntity.AllowAutoTranslation && ctrl.event.TranslatableEntity.LocalizedAlternativeLocale);
        }

        function setCurrentCultures() {
            ctrl.event._currentCulture = getCurrentCulture(ctrl.event.TranslatableEntity);

            if (ctrl.event.Update) {
                ctrl.event.Update._currentCulture = getCurrentCulture(ctrl.event.Update.TranslatableEntity);
            }

            if (ctrl.event.category) {
                ctrl.event.category._currentCulture = getCurrentCulture(ctrl.event.category.TranslatableEntity);
            }

            if (ctrl.event.CancelReason) {
                ctrl.event.CancelReason._currentCulture = getCurrentCulture(ctrl.event.CancelReason);
            }
        }

        function getCurrentCulture(translatedEntity) {
            if (!translationAllowed()) {
                return translatedEntity.LocalizedLocale;
            }

            return translatedEntity.PreferTranslatedVersion && translatedEntity.LocalizedAlternativeLocale ?
                translatedEntity.LocalizedAlternativeLocale : translatedEntity.LocalizedLocale;
        }

        function isSomeTranslated() {
            return false;
        }

        function isAllTranslated() {
            return eventCanBeTranslated() && updateCanBeTranslated() && categoryCanBeTranslated();

            function eventCanBeTranslated() {
                if (ctrl.event.CanBeTranslated) {
                    return ctrl.event.TranslatedTitle &&
                        (!ctrl.event.Summary || ctrl.event.TranslatedSummary) &&
                        (!ctrl.event.CancelReason || ctrl.event.TranslatedCancelReason)
                }
                return true
            }

            function updateCanBeTranslated() {
                if (ctrl.event.Update?.CanBeTranslated) {
                    return !ctrl.event.Update || ctrl.event.Update?.TranslatedBodyText
                }
                return true
            }

            function categoryCanBeTranslated() {
                if (ctrl.event.category?.CanBeTranslated) {
                    return ctrl.event.category.TranslatedTitle
                }
                return true
            }
        }

        function toggleTranslation() {
            if (ctrl.loadTranslation) {
                return;
            }

            ctrl.loadTranslation = true;

            const translatableEntities = {
                Event: ctrl.event.TranslatableEntity,
                Category: ctrl.event.category?.TranslatableEntity,
                EventUpdate: ctrl.event.Update?.TranslatableEntity,
                CancelReason: ctrl.event.CancelReason
            }

            TranslationService.switchCalendarTranslatableLocale(translatableEntities, ctrl.event.CalendarEventToken).then(resp => {
                ctrl.loadTranslation = false;
                ctrl.showOriginal = !ctrl.showOriginal;
                ctrl.showOriginalLabel = !ctrl.showOriginalLabel;

                setNewLocale(resp);
            }).finally(() => ctrl.loadTranslation = false)
        }

        function setNewLocale(translatableEntities) {
            ctrl.event.TranslatableEntity = translatableEntities.Event;

            if (translatableEntities.Event._currentCulture) {
                ctrl.event._currentCulture = translatableEntities.Event._currentCulture;
            }

            if (translatableEntities.Category?._currentCulture) {
                ctrl.event.category.TranslatableEntity = translatableEntities.Category;
                ctrl.event.category._currentCulture = translatableEntities.Category._currentCulture;
            }

            if (translatableEntities.EventUpdate?._currentCulture) {
                ctrl.event.Update.TranslatableEntity = translatableEntities.EventUpdate;
                ctrl.event.Update._currentCulture = translatableEntities.EventUpdate._currentCulture;
            }

            if (translatableEntities.CancelReason) {
                ctrl.event.CancelReason = translatableEntities.CancelReason;
            }
        }

        function updateEvent(newEvent) {
            const translatableEntities = {
                Event: _.cloneDeep(ctrl.event.TranslatableEntity),
                Category: _.cloneDeep(ctrl.event.category?.TranslatableEntity),
                EventUpdate: _.cloneDeep(ctrl.event.Update?.TranslatableEntity),
                CancelReason: _.cloneDeep(ctrl.event.CancelReason),
            }

            ctrl.event = _.cloneDeep(newEvent);
            setCurrentCultures();
            setNewLocale(translatableEntities);
        }

        function cancelEvent() {
            CancelEventService.cancelEvent(ctrl.event, (event) => {
                if (event.CancelReason) {
                    ctrl.event.CancelReason = _.cloneDeep(event.CancelReason);
                    ctrl.event.CancelReason._currentCulture =
                        ctrl.event._currentCulture.CultureId === event.CancelReason.LocalizedAlternativeLocale?.CultureId ?
                            event.CancelReason.LocalizedAlternativeLocale : event.CancelReason.LocalizedLocale;
                }

                ctrl.event.IsCancelled = true;
                $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
                ToastFactory.successTranslated('CALENDAR.EVENT.CANCELLATION_SENT');
            });
        }

        function eventUpdatesRendered() {
            if (ctrl.isUpdate) {
                const eventPosition = $element.find('event-update').position().top;
                $element.find('.popup-content-inner').scrollTop(eventPosition - 15);
            }
        }

        function destroy() {
            $element.find('.popup-content-inner').off('scroll');
            removeLocationListener();
            removeSignupsListener();
            fillingCreatedListener();
        }

        function close(isBack, isDelete) {
            popup.remove();
            ctrl.onPopupRegistered && $element.remove();
            destroy();
            ctrl.isManual = true;
            if (!isBack) {
                if ($stateParams.backState && $stateParams.backState !== 'calendar') {
                    Page.stateGoPrevious();
                } else {
                    Page.stateGo('calendar', $stateParams.token, null, null, null, false);
                }
            }
        }

        function editEvent() {
            close();
            CreateUpdateEventService.openEditEvent(ctrl.event);
        }

        function deleteEvent() {
            const opts = {
                message: 'CALENDAR.EVENT.DELETE_CONFIRM',
                class: 'calendar-delete-confirm'
            };

            ConfirmPopupService.open(opts).then(function () {
                CalendarRenderService.deleteEvent(ctrl.event).then(() => {
                    $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
                    ToastFactory.successTranslated('CALENDAR.EVENT.DELETED');
                    close(null, true);
                });
            });
        }

        function signUpForEvent(attendingStatus) {
            if (!attendingStatus || !attendingStatus.token) {
                return signUp(attendingStatus, ctrl.event)
            } else {
                const isAttending = attendingStatus.id === 1;
                const isTentative = attendingStatus.id === 2;
                const isNotAttending = attendingStatus.id === 3;

                if (isAttending) {
                    if (ctrl.event.IsAttendingFormActive) {
                        delete ctrl.event.ServiceFormFillingToken;
                        return signUpWithForm(attendingStatus);
                    } else {
                        return signUp(attendingStatus, ctrl.event);
                    }
                }

                if (isTentative) {
                    if (ctrl.event.IsTentativeFormActive) {
                        delete ctrl.event.ServiceFormFillingToken;
                        return signUpWithForm(attendingStatus);
                    } else {
                        return signUp(attendingStatus, ctrl.event);
                    }
                }

                if (isNotAttending) {
                    if (ctrl.event.IsNotAttendingFormActive) {
                        return signUpWithForm(attendingStatus);
                    } else {
                        return signUp(attendingStatus, ctrl.event);
                    }
                }
            }
        }

        function redoForm() {
            const isRedoForm = true;

            delete ctrl.event.ServiceFormFillingToken;
            signUpWithForm(ctrl.attendingStatuses.find(status => status.id === ctrl.event.AttendingStatus), isRedoForm);
        }

        function signUp(attendingStatus, event) {
            const attendingStatusId = attendingStatus && attendingStatus.id;
            const firstDecline = event ? !event.AttendingStatus : null;

            const eventSignup = async (attendingStatusId) => {
                const event = await CalendarRenderService.eventSignup(ctrl.event.CalendarEventId, attendingStatusId);
                $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
                ctrl.event = event;
                setCurrentCultures();
                if (ctrl.event.SignupEnabled) {
                    setSpotTextAndClass();
                }
            };

            const popupOptions = {
                message: 'CALENDAR.EVENT.SIGNOUT_CONFIRM_QUESTION',
                class: 'calendar-delete-confirm'
            };

            if (attendingStatusId === 3) {
                if (firstDecline) {
                    return eventSignup(attendingStatusId).then(() => {
                        if (attendingStatus) {
                            ToastFactory.errorTranslated('CALENDAR.EVENT.DECLINE_CONFIRM_MESSAGE');
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    return ConfirmPopupService.open(popupOptions).then(() => {
                        return eventSignup(attendingStatusId).then(() => {
                            if (attendingStatus) {
                                ToastFactory.errorTranslated('CALENDAR.EVENT.DECLINE_CONFIRM_MESSAGE');
                            }
                        }).catch((error) => {
                            console.error(error);
                        });
                    });
                }
            } else {
                return eventSignup(attendingStatusId).then(() => {
                    if ([1, 2].includes(attendingStatusId) || ctrl.event.CalendarEventTypeId !== ctrl.eventTypes.ToDo) {
                        ToastFactory.successTranslated('CALENDAR.EVENT.SIGNUP_CONFIRM_MESSAGE');
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        }

        function signUpWithForm(attendingStatus, isRedoForm) {
            const firstDecline = !ctrl.event.AttendingStatus;

            const popupOptions = {
                message: 'CALENDAR.EVENT.SIGNOUT_CONFIRM_QUESTION',
                class: 'calendar-delete-confirm'
            };

            const signupCallback = (event, isDraft) => {
                if (!isDraft && event) {
                    ctrl.event = event;
                }

                ctrl.eventHasDraft = isDraft;
                $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
                setCurrentCultures();
                if (ctrl.event.SignupEnabled) {
                    setSpotTextAndClass();
                }
            };

            if (firstDecline) {
                CalendarRenderService.eventSignupWithForm(attendingStatus, ctrl.event, ctrl.accountModuleToken, signupCallback);
            } else {
                if (attendingStatus.id === 3 && !ctrl.eventHasDraft && ctrl.event.IsCurrentUserSignedUp && !isRedoForm) {
                    return ConfirmPopupService.open(popupOptions).then(() => {
                        delete ctrl.event.ServiceFormFillingToken;
                        CalendarRenderService.eventSignupWithForm(attendingStatus, ctrl.event, ctrl.accountModuleToken, signupCallback);
                    })

                } else if ([1, 2].includes(attendingStatus)) {
                    delete ctrl.event.ServiceFormFillingToken;
                    return CalendarRenderService.eventSignupWithForm(attendingStatus, ctrl.event, ctrl.accountModuleToken, signupCallback);
                } else {
                    return CalendarRenderService.eventSignupWithForm(attendingStatus, ctrl.event, ctrl.accountModuleToken, signupCallback);
                }
            }
        }

        function openFilling() {
            if (!ctrl.event.IsDraft) {
                ServiceFormExternalService.openFillingPreviewPopup(ctrl.event.ServiceFormFillingToken);
            } else {
                signUpWithForm(ctrl.attendingStatuses.find(status => status.id === ctrl.event.AttendingStatus))
            }
        }

        function openSignups(attendanceType) {
            EventDetailsService.openSignups(ctrl.event, attendanceType)
        }

        function attendingEventClickHandler() {
            if (ctrl.event.AttendingStatus) {
                ctrl.toggleSignUp();
            } else {
                ctrl.signUpForEvent(ctrl.attendingStatuses[0]);
            }
        }

        function toggleSignUp() {
            var isAttending = ctrl.event.IsCurrentUserSignedUp && ctrl.event.AttendingStatus === 1;
            var isNotAttending = !ctrl.event.IsCurrentUserSignedUp && ctrl.event.AttendingStatus === 3;
            ActionSheetService
                .create([
                    {
                        text: 'CALENDAR.EVENT.ATTENDING',
                        icon: 'calendar-check',
                        iconClass: isAttending ? 'red label-color' : '',
                        rightIconClass: isAttending ? 'red' : '',
                        rightPrefix: isAttending ? 'far' : '',
                        rightIcon: isAttending ? 'dot-circle' : 'circle',
                        onClick: () => {
                            ctrl.event.AttendingStatus !== ctrl.attendingStatuses[0].id && ctrl.signUpForEvent(ctrl.attendingStatuses[0]);
                        }
                    },
                    {
                        text: 'CALENDAR.EVENT.NOT_ATTENDING',
                        icon: 'calendar-times',
                        iconClass: isNotAttending ? 'red label-color' : '',
                        rightIconClass: isNotAttending ? 'red' : '',
                        rightPrefix: isNotAttending ? 'far' : '',
                        rightIcon: isNotAttending ? 'dot-circle' : 'circle',
                        onClick: () => {
                            ctrl.event.AttendingStatus !== ctrl.attendingStatuses[2].id && ctrl.signUpForEvent(ctrl.attendingStatuses[2]);
                        }
                    }
                ], $element.find('footer')[0], { description: 'CALENDAR.EVENT.ATTENDING' })
                .show()
        }

        function openOptions($event) {
            const buttons = [],
                downloadLink = window.location.origin + ctrl.event.DownloadLink;
            let downloadButton;

            if (ctrl.event.IsModerationAllowed) {
                buttons.push({
                    text: 'CALENDAR.EVENT.UPDATE_EVENT',
                    icon: 'edit',
                    onClick: editEvent
                })
            }

            if (ctrl.event.EventUpdatesEnabled
                && ctrl.event.CalendarEventTypeId === ctrl.eventTypes.Event
                && ctrl.event.IsModerationAllowed
                && !ctrl.event.IsCancelled
                && !ctrl.event.HasUpdate) {
                buttons.push({
                    text: 'CALENDAR.EVENT_UPDATE.WRITE_UPDATE',
                    icon: 'update-event',
                    onClick: () => {
                        openCreateEventUpdate()
                    }
                })
            }

            downloadButton = {
                text: 'CALENDAR.EVENT.DOWNLOAD',
                icon: 'calendar-plus',
            }

            if (ctrl.isIos) {
                downloadButton.onClick = () => window.location.href = downloadLink;
            } else {
                downloadButton.link = window.location.origin + ctrl.event.DownloadLink;
            }

            if (!ctrl.event.IsDraft
                && ctrl.event.ServiceFormFillingToken
                && ctrl.event.RegistrationStatus !== ctrl.registrationStatus.Closed) {
                buttons.push({
                    text: 'CALENDAR.EVENT.REDO_FORM',
                    icon: 'redo',
                    onClick: redoForm
                })
            }

            buttons.push(downloadButton);

            if (ctrl.event.CalendarEventTypeId === ctrl.eventTypes.Event
                && ctrl.event.IsModerationAllowed
                && !ctrl.event.IsCancelled) {
                buttons.push({
                    text: 'CALENDAR.EVENT.CANCEL_EVENT',
                    icon: 'calendar-times',
                    onClick: cancelEvent
                })
            }
            
            if (Page.getSettings()?.ContentReportingEnabled) {
                buttons.push({
                    text: 'REPORT.TITLE',
                    icon: 'exclamation-circle',
                    onClick: reportEvent
                });
            }
            
            if (ctrl.event.IsModerationAllowed) {
                buttons.push({
                    text: 'CALENDAR.EVENT.DELETE',
                    icon: 'delete',
                    iconClass: 'red label-color',
                    onClick: deleteEvent
                });
            }

            ActionSheetService.create(buttons, $event.currentTarget, null, { name: 'event-details' }).show()
        }

        function onReportSubmit(model) {
            return ReportPopupService.reportEvent({
                token: ctrl.event.CalendarEventToken,
                model,
            })
        }
        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportEvent() {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit);
        }


        function checkForAvailableSpots() {
            return ctrl.event.SignupLimit ? ctrl.event.Signups.length < ctrl.event.SignupLimit : true;
        }

        function setSpotTextAndClass() {
            if (ctrl.event.SignupLimit) {
                ctrl.spotsLeft = ctrl.event.SignupLimit - ctrl.event.Signups.length;
            }
            ctrl.spotText = '';
            ctrl.spotClass = '';

            if (ctrl.event.IsCurrentUserSignedUp) {
                ctrl.spotText = $filter('translate')('CALENDAR.EVENT.ATTENDING');
                ctrl.spotClass = 'attending';
            } else {
                if (!ctrl.event.SignupLimit) {
                    ctrl.spotText = $filter('translate')('CALENDAR.EVENT.UNLIMITED');
                } else if (ctrl.spotsLeft === 0) {
                    ctrl.spotText = $filter('translate')('CALENDAR.EVENT.FULLY_BOOKED');
                    ctrl.spotClass = 'exclamation';
                } else if (ctrl.spotsLeft <= (ctrl.event.SignupLimit / 3)) {
                    ctrl.spotText = ctrl.spotsLeft + ' ' + $filter('translate')('CALENDAR.EVENT.SPOT_LEFT');
                    ctrl.spotClass = 'exclamation';
                } else {
                    ctrl.spotText = ctrl.spotsLeft + ' ' + $filter('translate')('CALENDAR.EVENT.SPOTS_LEFT');
                }
            }
        }

        function onShowEventUpdateOptions($event) {
            const buttons = [];

            buttons.push({
                text: 'CALENDAR.EVENT_UPDATE.EDIT',
                icon: 'edit',
                onClick: function () {
                    openCreateEventUpdate(true)
                }
            })

            buttons.push({
                text: 'CALENDAR.EVENT_UPDATE.DELETE',
                icon: 'delete',
                onClick: function () {
                    deleteEventUpdate();
                }
            });

            ActionSheetService.create(buttons, $event.currentTarget, null, { name: 'event-details' }).show()
        }

        function deleteEventUpdate() {
            var opts = {
                message: 'CALENDAR.EVENT_UPDATE.DELETE_CONFIRM',
                class: 'calendar-delete-confirm'
            };

            ConfirmPopupService.open(opts).then(() => {
                CalendarRestService.deleteEventUpdate(ctrl.event.CalendarEventToken).then(event => {
                    onEventUpdateChanged(event);
                    ToastFactory.successTranslated('The event update was deleted');
                });
            });
        }

        function onEventUpdateChanged(event) {
            ctrl.event.Update = _.cloneDeep(event.Update);
            ctrl.event.Update._currentCulture =
                ctrl.event._currentCulture.CultureId === event.Update.TranslatableEntity.LocalizedAlternativeLocale?.CultureId ?
                    event.Update.TranslatableEntity.LocalizedAlternativeLocale : event.Update.TranslatableEntity.LocalizedLocale;
            ctrl.event.HasUpdate = event.EventModel.HasUpdate;
        }

        function openCreateEventUpdate(isUpdate) {
            AddEventUpdateService.openPopup({
                eventToken: ctrl.event.CalendarEventToken,
                isUpdate: isUpdate,
                onEventUpdateCreated: onEventUpdateChanged
            });
        }

        function noButtonClass() {
            return !ctrl.event.ServiceFormFillingToken ||
                (ctrl.event.RegistrationStatus === ctrl.registrationStatus.Closed && ctrl.event.ServiceFormFillingToken && !ctrl.event.IsCurrentUserSignedUp);
        }

        function toggleInterested() {
            ctrl.event.IsInterested = !ctrl.event.IsInterested;

            CalendarRenderService.toggleInterested(ctrl.event).then(() => {
                $rootScope.$broadcast('event-updated', ctrl.event.CalendarEventId);
            })
        }
    }
})();
