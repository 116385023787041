(function () {
    'use strict';

    angular
        .module('App')
        .directive('contentItem', ['$rootScope', '$http', '$location', 'TimeLocale', '$q', 'ModalService',
            'BookmarkService', 'Profile', '$translate', 'BasicHelper', 'ActionSheetService', 'ConfirmPopupService',
            '$filter', 'NotificationFactory', 'REACTION_TYPE_ID', '$timeout', 'ContentWrapperService', 'PopupWrapperService',
            'Page', 'RemindersService', 'MODULE_ID', 'AttachmentsViewerService', 'MentionService', 'DepartmentViewerService',
            'ToastFactory', 'ProfileViewerService', 'CopyLinkService', 'ReportPopupService', 'TranslationService',
            function ($rootScope, $http, $location, TimeLocale, $q, ModalService, BookmarkService,
                      Profile, $translate, BasicHelper, ActionSheetService, ConfirmPopupService, $filter,
                      NotificationFactory, REACTION_TYPE_ID, $timeout, ContentWrapperService, PopupWrapperService,
                      Page, RemindersService, MODULE_ID, AttachmentsViewerService, MentionService, DepartmentViewerService,
                      ToastFactory, ProfileViewerService, CopyLinkService, ReportPopupService, TranslationService) {
                return {
                    restrict: 'E',
                    template: require('./ContentItem.tpl.html'),
                    scope: {
                        item: '=',
                        categories: '=',
                        settings: '=',
                        allowCreation: '<',
                        showReadCount: '<',
                        swiped: '&',
                        read: '&',
                        onRemoved: '&',
                        onEdit: '&',
                        postPinned: '&',
                        openReactionsViewer: '<',
                        isPinEnabled: '<',
                        isRemindersEnabled: '<',
                        openComments: '=?'
                    },
                    controller: ['$scope', '$element', ContentItemController]
                };

                function ContentItemController($scope, $element) {
                    var isBookmarkUpdating = false,
                        DepartmentNames;

                    $scope.ProfileViewerService = ProfileViewerService;
                    $scope.showHint = false;
                    $scope.showOriginal = true;
                    $scope.isRTL = $rootScope.isRTL;
                    $scope.Time = TimeLocale;
                    $scope.BookmarkService = BookmarkService;
                    $scope.item.Reactions = $scope.item.Reactions || [];
                    $scope.showBookmarkMessage = false;
                    $scope.shouldBeExcluded = $scope.item.ShouldBeExcluded;
                    $scope.pinPost = pinPost;
                    $scope.showShares = showShares;
                    $scope.onReadMoreClick = onReadMoreClick;
                    $scope.toggleTranslation = toggleTranslation;
                    //open comments from notification
                    $scope.openComments = openComments;
                    //open comments from module
                    $scope.showComments = openComments;
                    $scope.isNewArticle = isNewArticle;
                    $scope.isTranslationAllowed = isTranslationAllowed;
                    $scope.selectCurrentLocale = ContentWrapperService.selectCurrentLocale;

                    initImagesListener();

                    $scope.openBookmarks = function (e) {
                        e && e.stopPropagation();
                        BookmarkService.openPopup();
                        return false;
                    };
                    $scope.showReactionsMessage = false;

                    $scope.item.Category = getCategory($scope.item.ContentCategoryToken);

                    $scope.item.TranslatableEntity._currentCulture = ContentWrapperService.selectCurrentLocale($scope.item.TranslatableEntity);

                    if ($scope.item.ContentCategoryTranslatableEntity) {
                        $scope.item.ContentCategoryTranslatableEntity._currentCulture = ContentWrapperService.selectCurrentLocale($scope.item.ContentCategoryTranslatableEntity);
                    }

                    if (isTranslationAllowed()) {
                        $scope.$watchGroup(['item.TranslatableEntity._currentCulture.CultureId', 
                            'item.ContentCategoryTranslatableEntity._currentCulture.CultureId'], () => {
                            setTranslationButton();
                        }, true);
                    }

                    $scope.showShareButton = $scope.settings.SocialMediaSharingSettings.Enabled
                        && $scope.item.SocialMediaSharing
                        && ($scope.settings.SocialMediaSharingSettings.FacebookEnabled
                            || $scope.settings.SocialMediaSharingSettings.LinkedInEnabled);

                    DepartmentNames = $scope.item.DepartmentNames.split(',');
                    if (DepartmentNames.length > 1) {
                        $scope.item.DepartmentNamesShort = DepartmentNames[0] + ' + ' + (DepartmentNames.length - 1);
                    }

                    // Swipe
                    $scope.SwipeContent = function () {
                        var d = $q.defer();
                        var res = $scope.swiped();
                        res.then(function (success) {
                            d.resolve(success);
                        }, function (err) {
                            d.reject(err)
                        });
                        return d.promise;
                    };

                    function setTranslationButton() {
                        $scope.showOriginal = $scope.item.TranslatableEntity._currentCulture.CultureId === $scope.item.TranslatableEntity.LocalizedLocale.CultureId;

                        if ($scope.item.ContentCategoryTranslatableEntity?.LocalizedAlternativeLocale) {
                            $scope.showOriginal = $scope.showOriginal &&
                                $scope.item.ContentCategoryTranslatableEntity._currentCulture.CultureId === $scope.item.ContentCategoryTranslatableEntity.LocalizedLocale.CultureId;
                        }
                    }

                    function isTranslationAllowed() {
                        let isAllowed = $scope.item.TranslatableEntity.AllowAutoTranslation ||
                            (!$scope.item.TranslatableEntity.AllowAutoTranslation && $scope.item.TranslatableEntity.LocalizedAlternativeLocale);

                        if ($scope.item.ContentCategoryTranslatableEntity) {
                            isAllowed = isAllowed || $scope.item.ContentCategoryTranslatableEntity.AllowAutoTranslation ||
                                (!$scope.item.ContentCategoryTranslatableEntity.AllowAutoTranslation &&
                                    $scope.item.ContentCategoryTranslatableEntity.LocalizedAlternativeLocale);
                        }

                        return isAllowed;
                    }

                    // Comments
                    function openComments(commentItem) {
                        ContentWrapperService.showCommentsModal($scope.item, $scope.settings, commentItem, $scope.settings.accountModuleToken);
                    }

                    $scope.ToggleBookmark = function () {
                        if (!isBookmarkUpdating) {
                            if ($scope.item.BookmarkId) {
                                // Delete
                                isBookmarkUpdating = true;
                                BookmarkService.deleteBookmark($scope.item.BookmarkId).then(function (success) {
                                    if (success) {
                                        isBookmarkUpdating = false;
                                        $scope.item.BookmarkId = null;
                                    }
                                });
                            } else {
                                // Create
                                isBookmarkUpdating = true;
                                BookmarkService.createBookmark(
                                    $scope.item.AccountModuleId,
                                    9,
                                    $scope.item.ContentId,
                                    $scope.item.TranslatableEntity._currentCulture.Title
                                ).then(bookmarkId => {
                                    if (bookmarkId) {
                                        isBookmarkUpdating = false;
                                        $scope.item.BookmarkId = bookmarkId;
                                        showBookmarkMessage();
                                    }
                                });
                            }
                        }
                    };

                    $scope.Like = function () {
                        if (!$scope.item.ReactionTypeId) {
                            // Add reaction
                            $scope.SendReaction(REACTION_TYPE_ID.LIKE).then(function () {
                                $scope.item.ReactionTypeId = REACTION_TYPE_ID.LIKE;
                            });
                        } else {
                            // Remove
                            $scope.RemoveReaction($scope.item.ContentToken).then(function () {
                                $scope.item.ReactionTypeId = null;
                            });
                        }
                    };

                    $scope.ShowLikes = function () {
                        if ($scope.settings.AllowLike) {
                            ModalService.showModal({
                                template: require('../Likes/ContentLikes.tpl.html'),
                                controller: 'ContentLikesController',
                                inputs: {content: $scope.item}
                            }).then(function (modal) {
                                // Returns the modal for further options if needed 
                            });
                        }
                    };

                    $scope.showAllDepartemtns = function () {
                        $scope.allDepartemtns = true;
                    };


                    $scope.showPostOptions = function (event) {
                        var buttons = [],
                            remind = RemindersService.getSetReminderString(_.get($scope, 'item.Reminder.ReminderDateTimeLocal'));

                        if ($scope.isRemindersEnabled && !$scope.item.IsFuturePost) {
                            buttons.push({
                                text: remind,
                                icon: 'alarm-clock',
                                onClick: function () {
                                    RemindersService.openSetReminders(
                                        $scope.settings.accountModuleToken,
                                        $scope.item.ContentId,
                                        MODULE_ID.CONTENT,
                                        _.get($scope, 'item.Reminder.ReminderDateTimeLocal') ?
                                            moment(_.get($scope, 'item.Reminder.ReminderDateTimeLocal')).toDate() : null,
                                        function (reminder) {
                                            $scope.item.Reminder = reminder;
                                        }
                                    );
                                }
                            });
                        }

                        if ($scope.item.ReplyToAuthorEnabled) {
                            buttons.push({
                                text: 'CONTENT.REPLY_TO_AUTHOR',
                                icon: 'user',
                                onClick: replyAuthor
                            })
                        }

                        if ($scope.settings.PinnedPostsEnabled && $scope.isPinEnabled && !$scope.item.IsFuturePost) {
                            buttons.push({
                                text: $scope.item.Pinned ? 'CONTENT.UNPIN' : 'CONTENT.PIN',
                                icon: 'pin',
                                iconClass: $scope.item.Pinned ? 'label-color active' : 'label-color',
                                prefix: $scope.item.Pinned ? 'fas' : 'fal',
                                onClick: function () {
                                    pinPost();
                                }
                            })
                        }
                        if (NotificationFactory.isEnabled() && !$scope.item.IsFuturePost) {
                            buttons.push({
                                text: ($scope.item.IsSubscribed) ? 'CONTENT.UNSUBSCRIBE' : 'CONTENT.SUBSCRIBE',
                                icon: 'notifications',
                                iconClass: ($scope.item.IsSubscribed) ? 'label-color active' : 'label-color',
                                prefix: ($scope.item.IsSubscribed) ? 'fas' : 'fal',
                                onClick: function () {
                                    toggleSubscribtion();
                                }
                            });
                        }
                        buttons.push({
                            text: 'COPY_LINK',
                            icon: 'link',
                            onClick: copyContentLink
                        });
                        if ($scope.settings.DownloadPdf) {
                            buttons.push({
                                text: 'CONTENT.DOWNLOAD',
                                icon: 'download',
                                link: '/ContentModule/GetContentPdf/' + $scope.item.ContentToken
                            })
                        }
                        if ($scope.item.CanEdit) {
                            buttons.push({
                                text: 'CONTENT.EDIT',
                                icon: 'edit',
                                onClick: editPost
                            });
                        }
                        if (Page.getSettings()?.ContentReportingEnabled) {
                            buttons.push({
                                text: 'REPORT.TITLE',
                                icon: 'exclamation-circle',
                                onClick: reportContent
                            });
                        }

                        if ($scope.item.CanDelete) {
                            buttons.push({
                                text: 'CONTENT.DELETE',
                                icon: 'delete',
                                iconClass: 'red label-color',
                                onClick: function () {
                                    deletePost();
                                }
                            });
                        }
                        // Close
                        var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {
                            name: 'content-item',
                            token: $scope.settings.accountModuleToken
                        });
                        actionSheet.show();
                    };

                    function deletePost() {
                        ConfirmPopupService.open({
                            message: $filter('translate')('CONTENT.CONFIRM_DELETE')
                        }).then(() => {
                            $http.post(`/ContentModule/DeletePost/${$scope.item.ContentToken}?AccountModuleToken=${$scope.settings.accountModuleToken}`)
                                .then((resp) => {
                                    if ($scope.onRemoved) {
                                        $scope.onRemoved({ badgeSubtractCount: resp.data.Subtract })
                                    }
                                });
                        });
                    }

                    function editPost() {
                        $http
                            .get(`/ContentModule/GetContentEditPage/${$scope.item.ContentToken}?AccountModuleToken=${$scope.settings.accountModuleToken}`)
                            .then(resp => $scope.onEdit(resp));
                    }

                    function copyContentLink() {
                        CopyLinkService.copy(
                            `/ContentModule/GenerateLink/${$scope.item.ContentToken}?AccountModuleToken=${$scope.settings.accountModuleToken}`,
                            $element.find('.app-link-url')
                        );
                    }

                    function onReportSubmit(model) {
                        return ReportPopupService.reportContentPost({
                            accountModuleToken: $scope.settings.accountModuleToken,
                            token: $scope.item.ContentToken,
                            model,
                        })
                    }

                    function onCloseReport(submitted) {
                        ReportPopupService.onReportSubmit(submitted);
                    }

                    function reportContent() {
                        ReportPopupService.openReportPopup(onCloseReport, onReportSubmit);
                    }

                    function toggleSubscribtion() {
                        if (NotificationFactory.isEnabled()) {
                            const url = $scope.item.IsSubscribed
                                ? '/ContentModule/Unsubscribe'
                                : '/ContentModule/Subscribe';
                            $scope.item.IsSubscribed = !$scope.item.IsSubscribed;
                            $http.post(url + '/' + $scope.item.ContentToken);
                        }
                    }

                    function showBookmarkMessage() {
                        $scope.showBookmarkMessage = true;

                        $timeout(function () {
                            $scope.showBookmarkMessage = false
                        }, 5000)
                    }

                    $scope.SendReaction = function (reactionTypeId) {
                        return $http.post('/ContentModule/Reaction/', {
                            ContentToken: $scope.item.ContentToken,
                            ReactionTypeId: reactionTypeId
                        });
                    };

                    $scope.RemoveReaction = function () {
                        return $http.post('/ContentModule/RemoveReaction/' + $scope.item.ContentToken);
                    };

                    $scope.ShowReactionsViewer = function () {
                        if ($scope.settings.AllowReactions) {
                            $scope.openReactionsViewer($scope.item.ContentToken);
                        }
                    };

                    $scope.OnToggleList = function (state) {
                        $scope.showReactionsMessage = state;
                    };

                    function replyAuthor() {
                        $http.post('/ContentModule/ReplyToAuthor/' + $scope.item.ContentToken).then(resp => {
                            Page.stateGo('chat', resp.data.ChatModuleToken,
                                null,
                                Page.getPageTransition(),
                                resp.data
                            );
                        })
                    }

                    function pinPost() {
                        var action = $scope.item.Pinned ? 'UnPin' : 'Pin';

                        $http.post('/ContentModule/' + action + '/' + $scope.item.ContentToken).then(function (data) {
                            $scope.item.Pinned = !$scope.item.Pinned;
                            $scope.postPinned && $scope.postPinned();
                        });
                    }

                    function GetReactionSummary(reactions) {
                        return reactions
                            .filter(function (r) {
                                return r.Count;
                            })
                            .splice(0, 3);
                    };

                    function showShares() {
                        PopupWrapperService.createDynamic(
                            '<content-shares-popup module-token="moduleToken"' +
                            ' module-item-id="moduleItemId"></content-shares-popup>',
                            {
                                moduleToken: $scope.settings.accountModuleToken,
                                moduleItemId: $scope.item.ContentId
                            }
                        );
                    }

                    function onReadMoreClick() {
                        if ($scope.settings.SeenByEnabled && !$scope.item.IsSeen) {
                            $scope.read()
                        }
                    }

                    function initImagesListener() {
                        if (Page.getSettings().Base.ImageZoomEnabled) {
                            $timeout(function () {
                                $element
                                    .find('.wysiwyg-result')
                                    .on('click', 'img', function (e) {
                                        var media = ContentWrapperService.generateMediaFromSource(e.currentTarget.src);
                                        AttachmentsViewerService.openViewerWithMedia([media]);
                                    });
                            })
                        }
                    }

                    function getCategory(categoryToken) {
                        return _.find($scope.categories, {ContentCategoryToken: categoryToken});
                    }

                    function isNewArticle() {
                        if (!$scope.settings.ShowUnreadLabel) {
                            return false;
                        }

                        if ($scope.settings.SwipeToRead && !$scope.settings.SeenByEnabled) {
                            return !$scope.item.IsSwiped
                        } else if (($scope.item.HideSwipeToConfirm || !$scope.settings.SwipeToRead) && $scope.settings.SeenByEnabled) {
                            return !$scope.item.IsSeen
                        } else if ($scope.settings.SwipeToRead && $scope.settings.SeenByEnabled && !$scope.item.HideSwipeToConfirm) {
                            return !$scope.item.IsSwiped
                        } else {
                            return false
                        }
                    }

                    function toggleTranslation() {
                        if ($scope.isTranslating) {
                            return;
                        }

                        $scope.isTranslating = true;

                        const translatableEntities = {
                            Post: $scope.item.TranslatableEntity,
                            Category: $scope.item.ContentCategoryTranslatableEntity,
                        }

                        TranslationService.switchTranslatableLocaleContent(translatableEntities, $scope.item.ContentId).then(res => {
                            $scope.item.TranslatableEntity = res.Post && res.Post;
                            $scope.item.ContentCategoryTranslatableEntity = res.Category && res.Category;
                            $scope.item.TranslatableEntity._currentCulture.BodyText = parse($scope.item.TranslatableEntity._currentCulture);
                        })
                            .catch(() => ToastFactory.errorTranslated('TRANSLATION.CANT_TRANSLATE'))
                            .finally(() => {
                                $scope.isTranslating = false;
                                $timeout(() => {
                                    $scope.item.openReadMore = true;
                                })
                            });
                    }

                    function parse(translatableEntity) {
                        return MentionService.parseMentionsInText(
                            translatableEntity.BodyText,
                            $scope.item.TaggedUsers,
                            $scope.item.TaggedDepartments,
                            $scope.item.TaggedUserGroups,
                            false
                        );
                    }
                }
            }
        ]);
})();

