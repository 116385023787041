(function () {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('userManagement', {
                parent: 'base',
                url: '/userManagement/:token/:extra',
                template: require('./UserManagement.html'),
                resolve: {
                    userList: ['$http', 'base', '$stateParams', function ($http, base, $stateParams) {
                        if (base) {
                            const url = '/UserManagement/GetUsers/' + $stateParams.token +
                                ($stateParams.extra === 'recent' ? '?showOnlyRecent=true' : '')

                            return $http.get(url).then(resp => ({
                                ...resp.data,
                                Recent: $stateParams.extra === 'recent'
                            }));
                        }
                    }]
                },
                controller: 'UserManagementController as ctrl'
            });
        }])
        .controller('UserManagementController', ['$rootScope', '$scope', '$stateParams', 'Page', 'userList', 'events',
            'ModuleSearchService', 'TemplateFactory', 'HeaderButtonsFactory', 'UserManagementService', 'HowToGuideService',
            function ($rootScope, $scope, $stateParams, Page, userList, events, ModuleSearchService, TemplateFactory,
                      HeaderButtonsFactory, UserManagementService, HowToGuideService) {
                const ctrl = this;
                let searchUsers = [], searchPendingUsers = [];

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    Page.showBackButton('home');
                }

                Page.setTitle(userList.PageTitle);
                Page.trackPageView();

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });


                $scope.$on("$destroy", destroy);

                ctrl.status = ['today', 'week', 'month', 'had-activity', 'no-activity', 'pending'];
                ctrl.filtersCount = userList.Recent ? 1 : 0;
                ctrl.activeView = 1;
                ctrl.users = _.take(userList.Users, 50);
                ctrl.pendingUsers = userList.PendingUsers;
                ctrl.settings = userList.Settings;
                ctrl.isNative = TemplateFactory.isNative;
                ctrl.UserSignUpEnabled = userList.UserSignUpEnabled;
                ctrl.enableHowToGuide = userList.HowToGuideEnabled;
                ctrl.userCount = userList.Users.length;
                ctrl.showFilters = false;
                updateActivityWidget(userList);
                $rootScope.HaveInfoButton = userList.HowToGuideEnabled;

                if (userList.Recent) {
                    ctrl.filterModel = UserManagementService.getCleanFilterModel();
                    ctrl.filterModel.filters.Recent = userList.Recent;
                }

                ctrl.paging = paging;
                ctrl.search = search;
                ctrl.editUser = editUser;
                ctrl.createUser = createUser;
                ctrl.openFilters = openFilters;
                ctrl.openInfoPopup = openInfoPopup;
                ctrl.getManagementFilters = getManagementFilters;
                ctrl.closeFilters = closeFilters;

                ctrl.searchSettings = {
                    onModelChange: ctrl.search,
                    model: ''
                };

                ModuleSearchService.enableSearch(ctrl.searchSettings);
                HeaderButtonsFactory.createButtonsList(getHeaderButtons);

                function paging() {
                    const hasNoSearchModel = !ctrl.searchSettings.model.length;
                    const hasNoSearchUsers = !searchUsers.length;                    
                    const currentUserList = (hasNoSearchModel && hasNoSearchUsers) ? userList.Users : searchUsers;
                
                    if (ctrl.users.length !== currentUserList.length) {
                        ctrl.users = _.take(currentUserList, ctrl.users.length + 20);
                    }
                }                

                function search(name) {
                    if (!name) {
                        searchUsers = [];
                        ctrl.users = _.take(userList.Users, 50);
                        return;
                    }
                    
                    searchUsers = searchFilter(userList.Users, name);
                    searchPendingUsers = searchFilter(userList.PendingUsers, name);
                    angular.element('.user-management').scrollTop(0);

                    ctrl.users = _.take(searchUsers, 50);
                    ctrl.pendingUsers = searchPendingUsers;
                }

                function searchFilter(users, name) {
                    return users
                        .filter(user => user.Name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                            user.Title.toLowerCase().indexOf(name.toLowerCase()) !== -1)
                }

                function editUser(user) {
                    if (ctrl.settings.EnableUserEdit) {
                        user.Selected = true;
                        Page.stateGo('userManagementUpdate', $stateParams.token, user.UserToken);
                    }
                }

                function createUser() {
                    if (ctrl.settings.EnableUserCreation) {
                        Page.stateGo('userManagementCreate', $stateParams.token);
                    }
                }

                function updateActivityWidget(filterUsersResponse) {
                    const {TotalUsers, TotalActiveUsersLast30Days, TotalActiveUsersLast7Days, TotalUsersMatchingFilters} = filterUsersResponse;
                    ctrl.totalUsersCount = TotalUsers;
                    ctrl.activeLastWeekCount = TotalActiveUsersLast7Days;
                    ctrl.activeLastMonthCount = TotalActiveUsersLast30Days;
                    ctrl.filterMatchingUsersCount = TotalUsersMatchingFilters;
                }

                function getHeaderButtons() {
                    return [{
                        icon: 'info',
                        place: 'right',
                        onClick: openInfoPopup,
                        hidden: !ctrl.enableHowToGuide,
                    }, {
                        icon: 'filters',
                        activeIcon: 'filters-active',
                        place: 'right',
                        onClick: openFilters,
                        active: !!ctrl.filtersCount,
                    }, {
                        icon: 'search',
                        place: 'right'
                    }];
                }

                function destroy() {
                    HeaderButtonsFactory.resetButtonsList();
                    ModuleSearchService.disableSearch();
                }

                function closeFilters() {
                    ctrl.showFilters = false;
                }

                function getManagementFilters() {
                    ctrl.showFilters = true;
                    ctrl.managementFilters = {
                        allowedDepartments: userList.AllowedDepartments,
                        filterModel: _.cloneDeep(ctrl.filterModel),
                        onFiltered: (response, filterModel, filtersCount) => {
                                const {Users, PendingUsers} = response;
                                userList.Users = Users;
                                userList.PendingUsers = PendingUsers;
                                ctrl.users = _.take(userList.Users, 50);
                                ctrl.pendingUsers = userList.PendingUsers;

                                ctrl.filtersCount = filtersCount;
                                ctrl.filterModel = filterModel;
                                updateActivityWidget(response);

                                HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                            }, 
                        managerViewEnabled: ctrl.settings.ActivityManagerViewEnabled
                    }
                }

                function openFilters() {
                    getManagementFilters();
                    const { allowedDepartments, filterModel, onFiltered, managerViewEnabled } = ctrl.managementFilters;
                    UserManagementService
                        .openFiltersPopup(allowedDepartments, filterModel, onFiltered, managerViewEnabled);
                }

                function openInfoPopup() {
                    HowToGuideService.renderHowToGuidePopup($stateParams.token);
                }
            }
        ]);
})();   